import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Wrapper from '../components/wrapper';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Wrapper>
      <h2>NOT FOUND</h2>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
